import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ServicesIntroduction = () => {
    return (
        <Container fluid className="contact-info">
            <Row className="align-items-center">
                <Col md={6} className={"contact-text"}>
                    <h2>Bereit mit mir zusammenzuarbeiten?</h2>
                    <h3>Kontaktieren Sie mich noch heute</h3>
                    <p>Wenn Sie auf der Suche nach einem zuverlässigen IT-Partner sind, der Ihnen innovative Lösungen bietet, dann sind Sie bei mir genau richtig. Kontaktieren Sie mich noch heute, um mehr über meine Dienstleistungen zu erfahren und eine maßgeschneiderte Lösung für Ihr Unternehmen zu erhalten.</p>
                    <a href="mailto:info@ld-it-engineering.de?subject=Anfrage via Website&body=Hallo, ich habe Interesse an Ihren Dienstleistungen." className="learn-more-btn">Jetzt kontaktieren</a>
                </Col>
                <Col md={4} className={"contact-img"}>
                    <img src="/img/contact.jpg" alt="Contact" className="img-fluid" />
                </Col>
            </Row>
        </Container>
    );
};

export default ServicesIntroduction;

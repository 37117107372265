import { useMediaQuery } from 'react-responsive';

function Footer() {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <footer className="footer">
            <div className={`footer-content ${isMobile ? 'mobile' : ''}`}>
                <p>&copy; 2024 Ludwig Deminenko IT Engineering. Alle Rechte vorbehalten.</p>
            </div>
        </footer>
    );
}

export default Footer;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap'; // Import Collapse component from react-bootstrap

function MobileMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="mobile-menu">
            <button className="mobile-menu-toggle" onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </button>
            {/* Use Bootstrap's Collapse component for the navigation */}
            <Collapse in={isOpen}>
                <nav className="mobile-nav">
                    <ul>
                        <li><Link to="/" onClick={toggleMenu}>Startseite</Link></li>
                        <li><Link to="/leistungen" onClick={toggleMenu}>Leistungen</Link></li>
                        <li><Link to="/kontakt" onClick={toggleMenu}>Kontakt</Link></li>
                        <li><Link to="/impressum" onClick={toggleMenu}>Impressum</Link></li>
                        <li><Link to="/datenschutz" onClick={toggleMenu}>Datenschutz</Link></li>
                    </ul>
                </nav>
            </Collapse>
        </div>
    );
}

export default MobileMenu;

import Introduction from './Introduction';
import Header from "./Header";
import Footer from "./Footer";
import ServicesIntroduction from "./ServicesIntroduction";

function HomePage() {
    return (
        <main className="main-content">
            <Header />
            <Introduction />
            <ServicesIntroduction />
            <Footer />
        </main>
    );
}

export default HomePage;

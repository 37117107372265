import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const ServicesIntroduction = () => {
    return (
        <Container fluid className="services-intro">
            <Row className="no-gutters"> {/* Add the no-gutters class to remove negative margin */}
                <Col md={7} className="services-text">
                        <h2>Willkommen bei Ludwig Deminenko IT Engineering</h2>
                        <h3>Ihr Experte für Softwareentwicklung & IT-Administrationsdienstleistungen</h3>
                        <p>Ich bin ein qualifizierter IT-Freiberufler, spezialisiert auf Softwareentwicklung und IT-Verwaltung. Mein Ziel ist es, innovative Lösungen zu entwickeln, um Ihren Geschäftserfolg zu steigern.</p>
                    <Link to="/leistungen" className="learn-more-btn">Erfahren Sie mehr</Link>
                </Col>
                <Col md={3} className="services-image">
                    <img src="/img/service_introduction.jpg" alt="Service Introduction" className="img-fluid"/>
                </Col>
            </Row>
        </Container>

    );
};

export default ServicesIntroduction;

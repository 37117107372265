import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './components/HomePage';
import LeistungenPage from "./components/LeistungenPage";
import KontaktPage from "./components/KontaktPage";
import ImpressumPage from "./components/ImpressumPage";
import DatenschutzPage from "./components/DatenschutzPage";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/leistungen" element={<LeistungenPage />} />
                <Route path="/kontakt" element={<KontaktPage />} />
                <Route path="/impressum" element={<ImpressumPage />} />
                <Route path="/datenschutz" element={<DatenschutzPage />} />
            </Routes>
        </Router>
    );
}

export default App;
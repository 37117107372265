import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

function Introduction() {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div className="introduction">
            <Row>
                <Col xs={12} className={`intro-content ${isMobile ? 'mobile' : ''}`}>
                    <h1>Ihr vertrauenswürdiger IT-Partner</h1>
                    <p>Als freiberuflicher IT-Experte bin ich stolz darauf, ein vertrauenswürdiger Partner für Unternehmen aus verschiedenen Branchen, aber auch für einzelne Personen, zu sein.</p>
                    <p>Ich biete maßgeschneiderte Lösungen und IT-Administrationsdienstleistungen an, die sowohl kleinen Unternehmen als auch größeren Kunden gerecht werden.</p>
                    <p>Darüber hinaus stehen auch weitere Leistungen wie zum Beispiel Webseitenbau und Hosting zur Verfügung.</p>
                </Col>
            </Row>
        </div>
    );
}

export default Introduction;

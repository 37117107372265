import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Header from "./Header";
import Footer from "./Footer";

function DatenschutzPage() {
    return (
        <main>
            <Header />
            <Container  style={{ minHeight: "78.5vh", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "40px", marginBottom: "40px" }}>
                {datenschutzContent.map((section, index) => (
                    <Row key={index} className="impressum-section mb-5">
                        <Col className="text-center">
                            <h2>{section.title}</h2>
                            <LineBreakText text={section.text} />
                        </Col>

                    </Row>
                ))}
            </Container>
            <Footer />
        </main>
    );
}

const datenschutzContent = [
    { title: "Erhebung und Verarbeitung personenbezogener Daten", text: "Wir erheben und verarbeiten personenbezogene Daten gemäß den geltenden Datenschutzbestimmungen, insbesondere der Datenschutzgrundverordnung (DSGVO). " +
            "Personenbezogene Daten werden nur dann erhoben und gespeichert, wenn Sie uns diese freiwillig zur Verfügung stellen." },
    { title: "Verwendungszwecke", text: "Wir verwenden Ihre personenbezogene Daten ausschließlich für die folgenden Zwecke\n" +
            "- Zur Beantwortung Ihrer Anfragen und zur Kommunikation mit Ihnen\n" +
            "- Zur Abwicklung von Verträgen und zur Erbringung von Dienstleistungen\n" +
            "- Zur Verbesserung unseres Angebots und unserer Webseite\n" +
            "- Zur Zusendung von Informationen, Updates und Marketingmaterialien, sofern Sie dem zugestimmt haben" },
    { title: "Weitergabe von Daten", text: "Wir geben Ihre personenbezogenen Daten nicht ohne Ihre ausdrückliche Zustimmung an Dritte weiter, es sei denn, dies ist gesetzlich erforderlich oder zur Erfüllung unserer Vertragsverpflichtung notwendig." },
    { title: "Datensicherheit", text: "Wir treffen angemessene technische und organisatorische Maßnahmen, um Ihre personenbezogenen Daten vor Verlust, Missbrauch und unbefugtem Zugriff zu schützen." },
];

const LineBreakText = ({ text }) => {
    const textParts = text.split('\n').map((part, index) => (
        <React.Fragment key={index}>
            {part}
            <br />
        </React.Fragment>
    ));

    return <>{textParts}</>;
};

export default DatenschutzPage;

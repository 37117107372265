import Header from "./Header";
import Footer from "./Footer";
import ContactInfo from "./ContactInfo";

function KontaktPage() {
    return (
        <main>
            <Header />
            <ContactInfo />
            <Footer />
        </main>
    );
}

export default KontaktPage;
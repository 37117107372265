import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Container, Row, Col } from 'react-bootstrap';

function ImpressumPage() {
    return (
        <main>
            <Header />
            <Container>
                <div className="impressum-content">
                    {impressumContent.map((section, index) => (
                        <Row key={index} className="impressum-section">
                            <Col>
                                <h2>{section.title}</h2>
                                <LineBreakText text={section.text} />
                            </Col>
                        </Row>
                    ))}
                </div>
            </Container>
            <Footer />
        </main>
    );
}

const impressumContent = [
    { title: "Impressum", text: "Angaben gemäß § 5 TMG" },
    { title: "Adresse", text: "Ludwig Deminenko IT Engineering\nWaldmannsbreite 33\n34497 Korbach" },
    { title: "Kontakt", text: "E-Mail: info@ld-it-engineering.de\n\nVerantwortlich für den Inhalt nach § 55 Abs. 2 RStV:\nLudwig Deminenko\nWaldmannsbreite 33\n34497 Korbach" },
    { title: "Haftungsausschluss", text: "Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.\nFür die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.\n"},
];

const LineBreakText = ({ text }) => {
    const textParts = text.split('\n').map((part, index) => (
        <React.Fragment key={index}>
            {part}
            <br />
        </React.Fragment>
    ));

    return <>{textParts}</>;
};

export default ImpressumPage;
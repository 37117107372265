import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from "./MobileMenu";

function Header() {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <Container fluid className={`header ${isMobile ? 'mobile-header' : ''}`}>
            <Row>
                <Col>
                    <div className="header-content">
                        <h1 className="company-name">Ludwig Deminenko IT Engineering</h1>
                        {isDesktop && (
                            <nav className="main-nav">
                                <ul>
                                    <li><a href="/">Startseite</a></li>
                                    <li><a href="/leistungen">Leistungen</a></li>
                                    <li><a href="/kontakt">Kontakt</a></li>
                                    <li><a href="/impressum">Impressum</a></li>
                                    <li><a href="/datenschutz">Datenschutz</a></li>
                                </ul>
                            </nav>
                        )}
                        {(isTablet || isMobile) && <MobileMenu />}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Header;

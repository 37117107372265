import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from "./Header";
import Footer from "./Footer";

function LeistungenPage() {
    return (
        <main >
            <Header />
            <Container className="leistungen">
                {services.map((service, index) => (
                    <Row key={index} className="service-section">
                        <Col xs={12}>
                            <h2 style={index === 0 ? { fontSize: '2.3em' } : { fontSize: '1.5em' }}>{service.title}</h2>
                            <p>{service.text}</p>
                        </Col>
                    </Row>
                ))}
            </Container>
            <Footer />
        </main>
    );
}

const services = [
    {
        title: 'Meine Stärken',
        text: 'Warum sollten Sie mich wählen?'
    },
    {
        title: 'Softwareentwicklung',
        text: 'Ich verfüge über umfassende Erfahrung in der Entwicklung maßgeschneiderter Softwarelösungen. Mit Nutzung der neusten Technologien und Best Practices, liefere ich hochwertige Softwareprodukte, die Ihre Geschäftsprozesse optimieren.'
    },
    {
        title: 'Testautomatisierung',
        text: 'Durch den Einsatz modernster Testautomatisierungstools und -methoden verbessere ich Effizienz Ihrer Softwaretests. Ich garantiere, dass Ihre Software fehlerfrei und zuverlässig funktioniert, um maximale Kundenzufriedenheit zu gewährleisten.'
    },
    {
        title: 'IT-Verwaltung',
        text: 'Als erfahrener IT-Experte kümmere ich mich um die reibungslose Funktionsweise Ihrer IT-Infrastruktur. Von der Netzwerkadministartion bis zur Systemwartung stehe ich Ihnen zur Verfügung, um sicherzustellen, dass Ihre IT-Systeme optimal funktionieren und geschützt sind.'
    },
    {
        title: 'Kundenorientiert',
        text: 'Ich lege großen Wert auf die Bedürfnisse meiner Kunden und arbeite eng mit Ihnen zusammen, um maßgeschneiderte Lösungen anzubieten. Kundenzufriedenheit steht bei mir an erster Stelle, und ich bin stolz darauf, langfristige Partnerschaften aufzubauen.'
    }
];

export default LeistungenPage;
